import { graphql, useStaticQuery } from "gatsby"

export type ContentfulMediaProps = {
  title: string
  size: number
  url: string
  mimeType: string
  width: number
  height: number
}

export type ContentfulAboutEntriesProps = {
  title: string
  description: string
  imageLandscape: ContentfulMediaProps
  imagePortrait: ContentfulMediaProps
}

export type ContentfulFaqEntriesProps = {
  list: {
    question: {
      text: string
    }
    answer: {
      raw: string
    }
  }[]
}

export type ContentfulFooterListProps = {
  title: string
  link: string
}

export type ContentfulFooterEntriesProps = {
  titleLeft: string
  listLeft: ContentfulFooterListProps[]
  titleRight: string
  listRight: ContentfulFooterListProps[]
}

export type ContentfulHeroEntryFieldsProps = {
  title: string
  subtitle: {
    text: string
  }
  buttonPrimaryPreLabel?: string
  buttonPrimaryLabel?: string
  buttonPrimaryLink?: string
  buttonSecondaryPreLabel?: string
  buttonSecondaryLabel?: string
  buttonSecondaryLink?: string
  imageLandscape: ContentfulMediaProps
  videoLandscape: ContentfulMediaProps
  imagePortrait: ContentfulMediaProps
  videoPortrait: ContentfulMediaProps
  id: string
}

export type ContentfulHeroEntriesProps = {
  list: ContentfulHeroEntryFieldsProps[]
}

export type ContentfulMissionsEntriesProps = {
  list: {
    title: string
    description: {
      text: string
    }
    buttonPrimaryLabel: string
    buttonPrimaryLink: string
    buttonSecondaryLabel: string
    buttonSecondaryLink: string
    id: string
  }[]
}

export type ContentfulTrendingEntriesProps = {
  list: {
    title: string
    buttonPrimaryLabel: string
    buttonPrimaryLink: string
    image: ContentfulMediaProps
    background: ContentfulMediaProps
    id: string
  }[]
}

export type ContentfulWorldsEntriesProps = {
  title: string
  subtitle: string
  description: {
    text: string
  }
  buttonPrimaryLabel: string
  buttonPrimaryLink: string
  buttonSecondaryLabel: string
  buttonSecondaryLink: string
  imageLandscape: ContentfulMediaProps
  imagePortrait: ContentfulMediaProps
}

export type ContentfulCreatorsHeroEntryFieldsProps = {
  titleFirstLine: string
  changingWord: string[]
  titleLastLine: string
  subtitle: string
  imageLandscape: ContentfulMediaProps
  videoLandscape: ContentfulMediaProps
  imagePortrait: ContentfulMediaProps
  videoPortrait: ContentfulMediaProps
  id: string
}

export type ContentfulCreatorsWhyEntryProps = {
  title: string
  description: string
  image: ContentfulMediaProps
  id: string
}

export type ContentfulCreatorsWhyEntriesProps = {
  list: ContentfulCreatorsWhyEntryProps[]
}

export type ContentfulCreatorsCreateEntryProps = {
  title: string
  description: string
  image: ContentfulMediaProps
  imageBackground: ContentfulMediaProps
  tab1Title: string
  tab1DescriptionTitle: string
  tab1DescriptionSubTitle: string
  tab1Skills: string[]
  tab1Links: {
    raw: string
  }
  tab2Title: string
  tab2DescriptionTitle: string
  tab2DescriptionSubTitle: string
  tab2Skills: string[]
  tab2Links: {
    raw: string
  }
  id: string
}

export type ContentfulCreatorsCreateEntriesProps = {
  list: ContentfulCreatorsCreateEntryProps[]
}

export type ContentfulCreatorsConnectEntryProps = {
  name: string
  description: string
  image: ContentfulMediaProps
  url: string
  id: string
}

export type ContentfulCreatorsConnectEntriesProps = {
  list: ContentfulCreatorsConnectEntryProps[]
}

export type ContentfulCreatorsLearnEntryProps = {
  title: string
  name: string
  userImage: ContentfulMediaProps
  image: ContentfulMediaProps
  url: string
  date: string
  id: string
}

export type ContentfulCreatorsLearnEntriesProps = {
  list: ContentfulCreatorsLearnEntryProps[]
}

export type ContentfulBannerCTAEntryFieldsProps = {
  title: string
  subtitle: string
  buttonLabel: string
  url: string
  imageLandscape: ContentfulMediaProps
  videoLandscape: ContentfulMediaProps
  imagePortrait: ContentfulMediaProps
  videoPortrait: ContentfulMediaProps
  textPosition: "left" | "center"
  titlePosition: "first" | "center"
  id: string
}

export type ContentfulMissionsV2EntriesProps = {
  title: string
  description: {
    description: string
  }
  buttonLabel: string
  buttonLink: string
  buttonType: "primary" | "secondary"
  videoLandscape: ContentfulMediaProps
  videoPortrait: ContentfulMediaProps
  id: string
}

export type ContentfulWhatsHotEntriesProps = {
  title: string
  subtitle: {
    subtitle: string
  }
  buttonLink: string
  image: ContentfulMediaProps
  id: string
}

export type ContentfulMissionsV2ListProps = {
  list: ContentfulMissionsV2EntriesProps[]
}

export type ContentfulWhatsHotListProps = {
  list: ContentfulWhatsHotEntriesProps[]
}

export type ContentfulTextMarqueeEntry = {
  text: {
    text: string
  }
  id: string
}

export type ContentfulLandingContentProps = {
  hero: ContentfulHeroEntriesProps
  about: ContentfulAboutEntriesProps
  missions: ContentfulMissionsEntriesProps
  missionsv2: ContentfulMissionsV2ListProps
  trending: ContentfulTrendingEntriesProps
  worlds: ContentfulWorldsEntriesProps
  faq: ContentfulFaqEntriesProps
  footer: ContentfulFooterEntriesProps
  creatorsHero: ContentfulCreatorsHeroEntryFieldsProps
  creatorsWhy: ContentfulCreatorsWhyEntriesProps
  creatorsCreate: ContentfulCreatorsCreateEntriesProps
  creatorsConnect: ContentfulCreatorsConnectEntriesProps
  creatorsLearn: ContentfulCreatorsLearnEntriesProps
  creatorsFaq: ContentfulFaqEntriesProps
  supportFaq: ContentfulFaqEntriesProps
  createAvatarBanner: ContentfulBannerCTAEntryFieldsProps
  startExploringBanner: ContentfulBannerCTAEntryFieldsProps
  whatsHot: ContentfulWhatsHotListProps
  textMarquee: ContentfulTextMarqueeEntry
}

export default function useLandingContentfulQuery(): ContentfulLandingContentProps {
  return useStaticQuery(graphql`
    query LandingQuery {
      hero: contentfulLandingMissionsSection(contentfulid: { eq: "hero" }) {
        list {
          ... on ContentfulLandingHero {
            title
            subtitle {
              text: subtitle
            }
            buttonPrimaryPreLabel
            buttonPrimaryLabel
            buttonPrimaryLink
            buttonSecondaryPreLabel
            buttonSecondaryLabel
            buttonSecondaryLink
            imageLandscape {
              title
              size
              url
              mimeType
              width
              height
            }
            videoLandscape {
              title
              size
              url
              mimeType
              width
              height
            }
            imagePortrait {
              title
              size
              url
              mimeType
              width
              height
            }
            videoPortrait {
              title
              size
              url
              mimeType
              width
              height
            }
            id: contentfulid
          }
        }
      }
      about: contentfulLandingAbout(contentfulid: { eq: "about" }) {
        title
        imageLandscape {
          title
          size
          url
          mimeType
          width
          height
        }
        imagePortrait {
          title
          size
          url
          mimeType
          width
          height
        }
      }
      missions: contentfulLandingMissionsSection(
        contentfulid: { eq: "missions" }
      ) {
        list {
          ... on ContentfulLandingMissions {
            title
            description {
              text: description
            }
            buttonPrimaryLabel
            buttonPrimaryLink
            buttonSecondaryLabel
            buttonSecondaryLink
            id: contentfulid
          }
        }
      }
      missionsv2: contentfulLandingMissionsSection(
        contentfulid: { eq: "missions_v2" }
      ) {
        list {
          ... on ContentfulLandingMissionsV2 {
            title
            description {
              description
            }
            buttonLabel
            buttonLink
            buttonType
            videoLandscape {
              title
              size
              url
              mimeType
              width
              height
            }
            videoPortrait {
              title
              size
              url
              mimeType
              width
              height
            }
            id: contentfulid
          }
        }
      }
      trending: contentfulLandingMissionsSection(
        contentfulid: { eq: "trending" }
      ) {
        list {
          ... on ContentfulLandingTrending {
            title
            buttonPrimaryLabel
            buttonPrimaryLink
            image {
              title
              size
              url
              mimeType
              width
              height
            }
            background {
              title
              size
              url
              mimeType
              width
              height
            }
            id
          }
        }
      }
      worlds: contentfulLandingWorlds(contentfulid: { eq: "worlds" }) {
        title
        subtitle
        description {
          text: description
        }
        buttonPrimaryLabel
        buttonPrimaryLink
        buttonSecondaryLabel
        buttonSecondaryLink
        imageLandscape {
          title
          size
          url
          mimeType
          width
          height
        }
        imagePortrait {
          title
          size
          url
          mimeType
          width
          height
        }
      }
      faq: contentfulLandingMissionsSection(contentfulid: { eq: "faq" }) {
        list {
          ... on ContentfulLandingFaqItem {
            question {
              text: question
            }
            answer {
              raw
            }
          }
        }
      }
      footer: contentfulLandingFooterSection(contentfulid: { eq: "footer" }) {
        titleLeft
        listLeft {
          title
          link
        }
        titleRight
        listRight {
          title
          link
        }
      }
      creatorsHero: contentfulCreatorsLandingHero(
        contentfulid: { eq: "hero" }
      ) {
        titleFirstLine
        changingWord
        titleLastLine
        subtitle
        imageLandscape {
          title
          size
          url
          mimeType
          width
          height
        }
        videoLandscape {
          title
          size
          url
          mimeType
          width
          height
        }
        imagePortrait {
          title
          size
          url
          mimeType
          width
          height
        }
        videoPortrait {
          title
          size
          url
          mimeType
          width
          height
        }
        id: contentfulid
      }
      creatorsWhy: contentfulLandingMissionsSection(
        contentfulid: { eq: "creators_why" }
      ) {
        list {
          ... on ContentfulCreatorsLandingWhyItems {
            title
            description
            image {
              title
              size
              url
              mimeType
              width
              height
            }
            id: contentfulid
          }
        }
      }
      creatorsCreate: contentfulLandingMissionsSection(
        contentfulid: { eq: "creators_create" }
      ) {
        list {
          ... on ContentfulCreatorsLandingCreateItems {
            id: contentfulid
            title
            description
            image {
              title
              size
              url
              mimeType
              width
              height
            }
            imageBackground {
              title
              size
              url
              mimeType
              width
              height
            }
            tab1Title
            tab1DescriptionTitle
            tab1DescriptionSubTitle
            tab1Skills
            tab1Links {
              raw
            }
            tab2Title
            tab2DescriptionTitle
            tab2DescriptionSubTitle
            tab2Skills
            tab2Links {
              raw
            }
          }
        }
      }
      creatorsConnect: contentfulLandingMissionsSection(
        contentfulid: { eq: "creators_connect" }
      ) {
        list {
          ... on ContentfulCreatorsLandingConnectItems {
            name
            description
            image {
              title
              size
              url
              mimeType
              width
              height
            }
            url
            id: contentfulid
          }
        }
      }
      creatorsLearn: contentfulLandingMissionsSection(
        contentfulid: { eq: "creators_learn" }
      ) {
        list {
          ... on ContentfulCreatorsLandingLearnItems {
            title
            image {
              title
              size
              url
              mimeType
              width
              height
            }
            name
            userImage {
              title
              size
              url
              mimeType
              width
              height
            }
            url
            date(formatString: "MMMM DD, YYYY")
            id: contentfulid
          }
        }
      }
      creatorsFaq: contentfulLandingMissionsSection(
        contentfulid: { eq: "creators_faq" }
      ) {
        list {
          ... on ContentfulCreatorsLandingFaqItem {
            question {
              text: question
            }
            answer {
              raw
            }
          }
        }
      }
      supportFaq: contentfulLandingMissionsSection(
        contentfulid: { eq: "support_faq" }
      ) {
        list {
          ... on ContentfulSupportFaqItem {
            question {
              text: question
            }
            answer {
              raw
            }
          }
        }
      }
      createAvatarBanner: contentfulLandingBannerCta(
        contentfulid: { eq: "create_avatar" }
      ) {
        title
        subtitle
        buttonLabel
        url
        imageLandscape {
          title
          size
          url
          mimeType
          width
          height
        }
        videoLandscape {
          title
          size
          url
          mimeType
          width
          height
        }
        imagePortrait {
          title
          size
          url
          mimeType
          width
          height
        }
        videoPortrait {
          title
          size
          url
          mimeType
          width
          height
        }
        textPosition
        titlePosition
        id: contentfulid
      }
      startExploringBanner: contentfulLandingBannerCta(
        contentfulid: { eq: "start_exploring" }
      ) {
        title
        subtitle
        buttonLabel
        url
        imageLandscape {
          title
          size
          url
          mimeType
          width
          height
        }
        videoLandscape {
          title
          size
          url
          mimeType
          width
          height
        }
        imagePortrait {
          title
          size
          url
          mimeType
          width
          height
        }
        videoPortrait {
          title
          size
          url
          mimeType
          width
          height
        }
        textPosition
        titlePosition
        id: contentfulid
      }
      whatsHot: contentfulLandingMissionsSection(
        contentfulid: { eq: "whats_hot" }
      ) {
        list {
          ... on ContentfulLandingWhatsHot {
            id
            title
            subtitle {
              subtitle
            }
            buttonLink
            image {
              title
              size
              url
              mimeType
              width
              height
            }
          }
        }
      }
      textMarquee: contentfulLandingTextMarquee(
        contentfulid: { eq: "marquee" }
      ) {
        text {
          text
        }
        id: contentfulid
      }
    }
  `)
}
